<template>
  <div>
    <div v-if="this.$route.name === 'Invoice'">
      <Invoice />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import Invoice from "@/views/companyApp/invoice/Invoice.vue";

export default {
  name: 'InvoiceParent',
  components: {
    Invoice
  }
}
</script>
